import React, { useRef } from 'react';
import { IWeatherProps } from './types';
import WeatherCard from '@/components/widgets/weather/weather-card';
import { Column, Container, Row } from '@/components/base/gridview';
import useDeviceType from '@/hooks/useDeviceType';
import { useApp } from '@wap-client/core';
import { motion, useInView } from 'framer-motion';
import Anchor from '@/components/base/anchor';
import Icon from '@/components/base/icon';

const animations = {
  container: {
    hidden: {
      opacity: 0,
    },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.15,
        delayChildren: 0.3,
        duration: 0.3,
        ease: [0.25, 1, 0.5, 1],
      },
    },
  },
  title: {
    hidden: {
      opacity: 0,
      x: -100,
    },
    show: {
      opacity: 1,
      x: 0,
      transition: {
        duration: 0.75,
        ease: [0.25, 1, 0.5, 1],
      },
    },
  },
};

const Weather: React.FC<IWeatherProps> = ({
  moreLink,
  mbDesktop = 100,
  mtDesktop = 100,
  mtLaptop = 100,
  mbLaptop = 100,
  mbMobile = 30,
  mtMobile = 30,
}) => {
  const deviceType = useDeviceType();
  const app = useApp();
  const ref = useRef<HTMLElement>(null);
  const isInView = useInView(ref, { once: true });
  const city: string = 'İstanbul';
  const cities: Array<string> = ['İstanbul', 'Ankara', 'İzmir', 'Antalya'];

  return (
    <motion.section
      className="weather"
      variants={animations.container}
      initial="hidden"
      animate={`${isInView ? 'show' : 'hidden'}`}
      ref={ref}
      viewport={{ once: true, amount: 0.15 }}
      style={{
        marginTop:
          deviceType === 'mobile'
            ? mtMobile
            : deviceType === 'laptop'
            ? mtLaptop
            : mtDesktop,
        marginBottom:
          deviceType === 'mobile'
            ? mbMobile
            : deviceType === 'laptop'
            ? mbLaptop
            : mbDesktop,
      }}
    >
      <Container size="wide">
        <Row>
          {deviceType === 'mobile' ? (
            <Column xs={{ size: 12 }}>
              <WeatherCard city={city} cities={cities} />
            </Column>
          ) : (
            <>
              <motion.div
                className="weather-section-header"
                variants={animations.title}
              >
                <span className="header-title">
                  {app.settings.translations['weather']}
                </span>
                {moreLink && (
                  <Anchor {...moreLink} className="weather-more-anchor">
                    <span className="more-text">
                      {app.settings.translations['more-information-weather']}
                    </span>
                    <Icon name="icon-arrow-right-outline" />
                  </Anchor>
                )}
              </motion.div>
              {cities.map((city, index) => (
                <Column
                  xs={{ size: 6 }}
                  lg={{ size: 3 }}
                  key={index}
                  className="weather-card-col"
                >
                  <WeatherCard city={city} version={2} />
                </Column>
              ))}
            </>
          )}
          {moreLink && deviceType === 'mobile' && (
            <Anchor {...moreLink} className="weather-more-anchor">
              <span className="more-text">
                {app.settings.translations['more-information-weather']}
              </span>
              <Icon name="icon-arrow-right-outline" />
            </Anchor>
          )}
        </Row>
      </Container>
    </motion.section>
  );
};

export default Weather;
