import React from 'react';
import { IApexWeatherChartProps } from './types';
import dynamic from 'next/dynamic';
import { ApexOptions } from 'apexcharts';
import useDeviceType from '@/hooks/useDeviceType';
import { useApp } from '@wap-client/core';

const Chart = dynamic(() => import('react-apexcharts'), { ssr: false });

const ApexWeatherChart: React.FC<IApexWeatherChartProps> = ({
  height = 120,
}) => {
  const deviceType = useDeviceType();
  const app = useApp();

  const data = {
    options: {
      chart: {
        id: '-weather',
        toolbar: {
          show: false,
        },
      },
      tooltip: {
        enabled: false,
      },
      stroke: {
        show: false,
      },
      noData: {
        text: app.settings.translations['no-data-weather'],
        align: 'center',
        verticalAlign: 'top',
        offsetX: 0,
        offsetY: 0,
        style: {
          color: undefined,
          fontSize: '14px',
          fontFamily: undefined,
        },
      },
      xaxis: {
        type: 'category',
        categories: ['00.00', '06.00', '12.00', '18.00', '23.59'],
        position: 'bottom',
        labels: {
          show: true,
          style: {
            fontSize: '15px',
            fontWeight: 400,
            colors: '#000000',
          },
        },
        axisTicks: {
          show: false,
        },
        tooltip: {
          enabled: false,
        },
      },
      yaxis: {
        labels: {
          show: false,
        },
        tooltip: {
          enabled: false,
        },
      },
      grid: {
        show: false,
      },
      dataLabels: {
        enabled: true,
        textAnchor: 'middle',
        background: {
          opacity: 0.7,
        },
      },
      fill: {
        colors: ['#FFAA0C'],
        gradient: {
          shade: 'light',
          type: 'vertical',
          shadeIntensity: 0.4,
          gradientToColors: undefined,
          inverseColors: false,
          opacityFrom: 1,
          opacityTo: 0.5,
          stops: [40, 100],
          colorStops: [],
        },
      },
    } as ApexOptions,
    series: [
      {
        name: 'Degree',
        data: [0, 13, 22, 13, 0],
        color: '#FFAA0C',
        zIndex: 1,
      },
    ],
  };
  return (
    <div>
      {typeof window !== 'undefined' && (
        <Chart
          options={data.options}
          series={data.series}
          type="area"
          width="100%"
          height={height}
        />
      )}
    </div>
  );
};

export default ApexWeatherChart;
