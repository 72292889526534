import React, { useRef, useState } from 'react';
import { IWeatherCardProps } from './types';
import { functions, useApp } from '@wap-client/core';
import Icon from '@/components/base/icon';
import ApexWeatherChart from '@/components/widgets/weather/apex-weather-chart';
import Link from 'next/link';
import useDeviceType from '@/hooks/useDeviceType';
import { motion } from 'framer-motion';

const animations = {
  card: {
    hidden: {
      opacity: 0,
      y: 100,
    },
    show: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
        ease: [0.25, 1, 0.5, 1],
      },
    },
  },
};

const WeatherCard: React.FC<IWeatherCardProps> = ({
  city = 'İstanbul',
  cities = ['İstanbul'],
  version = 1,
  ...rest
}) => {
  const app = useApp();
  const deviceType = useDeviceType();
  const [activeCity, setActiveCity] = useState<string>(city);
  const [search, setSearch] = useState<string>('');
  const searchRef = useRef<HTMLInputElement>(null);

  const hanleActiveCity = (city: string) => {
    setActiveCity(city);
  };

  const hanleSearchInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  return (
    <motion.div
      className={functions.classnames(
        'weather-card',
        deviceType,
        `v${version}`
      )}
      variants={animations.card}
      {...(rest as any)}
    >
      <div className="weather-card-header">
        <span className="weather-card-header-title">
          {app.settings.translations['weather']}
        </span>
        <div className="weather-card-header-search">
          <Icon name="icon-search-menu" size="small" />
          <input
            type="text"
            name="weather-search"
            id="weather-search"
            placeholder={app.settings.translations['search'] || 'Search'}
            className={functions.classnames(
              search.length > 1 && 'active-search'
            )}
            ref={searchRef}
            value={search}
            onChange={hanleSearchInput}
          />
        </div>
      </div>
      <div className="weather-card-body">
        <div className="weather-card-body-cities">
          {deviceType === 'mobile' ? (
            cities.map((city, index) => (
              <button
                type="button"
                className={functions.classnames(
                  'city',
                  city === activeCity && 'active-city'
                )}
                key={index}
                onClick={() => hanleActiveCity(city)}
              >
                {city === activeCity && (
                  <Icon name={version === 1 ? 'icon-pin' : 'icon-location'} />
                )}
                {city}
              </button>
            ))
          ) : (
            <span className="city active-city">
              <Icon name={version === 1 ? 'icon-pin' : 'icon-location'} />
              {city}
              {version === 2 && <span className="now-date">18 July</span>}
            </span>
          )}
        </div>
        <div className="weather-card-body-texts">
          <div className="degree">
            <Icon name="icon-sun" />
            <span className="degree-text">20</span>
          </div>
          {version === 1 ? (
            <div className="other-degree">
              <span>Rain</span>
              <span>: 0%</span>
              <span>Humidity</span>
              <span>: 48%</span>
              <span>Wind</span>
              <span>: 23 km/s</span>
            </div>
          ) : (
            <div className="other-degree">
              <span>Rain: 0%</span>
              <span>Humidity: 48%</span>
              <span>Wind: 23 km/s</span>
            </div>
          )}
        </div>
        <div className="weather-card-body-statistic">
          <ApexWeatherChart height={version === 1 ? 120 : 100} />
        </div>
      </div>
      <div className="weather-card-footer"></div>
    </motion.div>
  );
};

export default WeatherCard;
